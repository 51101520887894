<template>
  <div class="page page-posts">
    <b-col class="py-3">
      <Breadcrumb :items="[{label: $t('News')}]"/>
      <div class="list-categories d-flex justify-content-between">
        <div class="button">
          <b-button :variant="idCategory == 0 ? 'primary' : 'outline-primary'" block class="btn-rounded font-weight-bold" @click="onCategoryClick(0)">{{ $t("All") }}
          </b-button>
        </div>
        <div class="button" v-for="(category, index) in categories" :key="index">
          <b-button :variant="idCategory == category.id ? 'primary' : 'outline-primary'" block class="btn-rounded font-weight-bold" @click="onCategoryClick(category.id)">{{ category.name }}
          </b-button>
        </div>
      </div>
      <div class="list-posts" v-if="posts.length === 0 && !loading">
        <NotFoundPanel/>
      </div>
      <div class="list-posts" v-else>
        <PostCard v-for="(post, index) in posts" :key="index" :post="post"/>
      </div>
      <LoadingPanel v-if="loading"/>
      <ErrorPanel v-if="error" :message="errorMessage" @retry="loadData()"/>
    </b-col>
  </div>
</template>

<script>
import PostCard from "@/components/cards/PostCard";
import postService from "@/services/postService";

let draw = 0;
let skip = 0;
let ended = false;
const take = 8;

export default {
  name: "Category",
  components: {PostCard},
  data() {
    return {
      loading: false,
      error: false,
      posts: [],
      categories: [],
      errorMessage: null,
      idCategory: 0
    }
  },
  async created() {
    skip = 0;
    ended = false;
    await this.loadData();
  },
  mounted() {
    this.$bridge.$on("scrollToBottom", this.loadData);
  },
  beforeDestroy() {
    this.$bridge.$off("scrollToBottom", this.loadData);
  },
  methods: {
    async loadData(renew = false) {
      if (renew) {
        skip = 0;
        ended = false;
        this.posts = [];
        this.error = false;
        this.errorMessage = null;
      } else {
        if (this.loading || ended)
          return;
      }

      this.loading = true;
      draw++;
      const response = await postService.list({
        idCategory: this.idCategory < 1 ? null : this.idCategory,
        take,
        skip,
        draw,
      })

      this.loading = false;
      if (!response) {
        this.error = true;
        this.errorMessage = "Connect to server failed. Please check your internet connection";
        return;
      }

      if (response.error) {
        this.error = true;
        this.errorMessage = error.message;
        return;
      }

      const data = response.data;
      if (data.draw === draw) {
        if (this.categories.length === 0)
          this.categories = data.categories;

        const totalPosts = data.posts.length;
        this.posts = [...this.posts, ...data.posts];
        skip += totalPosts;
        ended = totalPosts < take;
      }
    },
    onCategoryClick(idCategory) {
      this.idCategory = idCategory;
      this.loadData(true);
    }
  }
}
</script>

<style scoped lang="scss">
.page-posts {
  .list-categories {
    margin: 0 -5px;
    
    .button {
      flex: 1;
      padding: 5px 5px 15px 5px;
    }
  }

  .list-posts {
    padding: 15px 0;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
  }
}
</style>